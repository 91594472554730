<template>
  <div>
      <v-main>
        <v-container fluid>
          <v-row dense>
            <v-col cols="6">
              <Bar
                  :chart-options="labelData.chartOptions"
                  :chart-data="labelData.chartData"
                  :chart-id="labelProp.chartId"
                  :dataset-id-key="labelProp.datasetIdKey"
                  :plugins="labelProp.plugins"
                  :css-classes="labelProp.cssClasses"
                  :styles="labelProp.styles"
                  :width="labelProp.width"
                  :height="labelProp.height"
              />
            </v-col>
            <v-col cols="6">
              <Pie
                  :chart-options="pieData.chartOptions"
                  :chart-data="pieData.chartData"
                  :chart-id="pieProp.chartId"
                  :dataset-id-key="pieProp.datasetIdKey"
                  :plugins="pieProp.plugins"
                  :css-classes="pieProp.cssClasses"
                  :styles="pieProp.styles"
                  :width="pieProp.width"
                  :height="pieProp.height"
              />
            </v-col>
            <!--
              <v-col cols="4">
              <v-card
                outlined>
                <v-card-title>
                  Total Simpanan Wajib
                </v-card-title>
                <v-card-subtitle 
                  class="text-h5" 
                  align="left">
                  Rp500.000.000
                </v-card-subtitle>
                <v-card-actions>
                  <v-btn text small>
                    Lihat simpanan lain
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          -->
          </v-row>
          <v-divider />
          <!-- <v-row dense>
            <v-col cols="4">
              <v-card
                outlined>
                <v-card-title>
                  Belum bayar wajib bulan ini
                </v-card-title>
                <v-card-subtitle 
                  class="text-h5" 
                  align="left">
                  50
                </v-card-subtitle>
                <v-card-actions>
                  <v-btn text small>
                    Kirim Notifikasi Email
                  </v-btn>
                  <v-btn text small>
                    Detail
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
            <v-col cols="4">
              <v-card
                outlined>
                <v-card-title>
                  Menunggak pembayaran wajib
                </v-card-title>
                <v-card-subtitle 
                  class="text-h5" 
                  align="left">
                  50
                </v-card-subtitle>
                <v-card-actions>
                  <v-btn text small>
                    Kirim Notifikasi Email
                  </v-btn>
                  <v-btn text small>
                    Detail
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row> -->
        </v-container>
      </v-main>
    <OverlayProgress :overlay=$root.overlay :isError=$root.isError />
  </div>
  
</template>

<script>
import OverlayProgress from '@/components/OverlayProgress.vue'
import { Bar, Pie } from 'vue-chartjs/legacy'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, ArcElement, CategoryScale, LinearScale } from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, ArcElement, CategoryScale, LinearScale)

export default {
  components: {
    OverlayProgress,
    Bar,
    Pie
  },
  props: {
        labelProp : {
          type: Object,
          default() {
            return {
              chartId: 'bar-chart',
              datasetIdKey: 'label',
              width: 500,
              height: 350,
              cssClasses: '',
              styles: {},
              plugins: []
            }
          }
        },
        pieProp : {
          type: Object,
          default() {
            return {
              chartId: 'pie-chart',
              datasetIdKey: 'label',
              width: 500,
              height: 350,
              cssClasses: '',
              styles: {},
              plugins: []
            }
          }
        }
    },
  data() {
    return {
      labelData: {
        chartData: {
            labels: [ '2020', '2021', '2022' ],
            datasets: [ { 
              label: 'Grafik Pertumbuhan Anggota',
              backgroundColor: '#3949AB',
              data: [0, 0, 0],
            } ]
        },
        chartOptions: {
            responsive: false
        }
      },
      pieData: {
        chartData: {
            labels: ['Lajnah', 'Khuddam', 'Anshar'],
            datasets: [
              {
                label: '',
                backgroundColor: ['#41B883', '#E46651', '#00D8FF'],
                data: [0, 0, 0]
              }
            ]
        },
        chartOptions: {
            responsive: false
        }
      }
    }
  },
  methods:{
    async getData() {
        this.$root.api.sentAPI(
        {
            method: 'get',
            api: this.$root.api.get.dashboard.chart+'?type=pie',
        }).then((res) => {
          if(res.responseStatus.responseCode == '00') {
            this.pieData.chartData = res.data
          }
        })
        .catch((err) => {
            this.$root.isError = true
            if(!navigator.onLine) this.$root.isError = err.message
        })

        this.$root.api.sentAPI(
        {
            method: 'get',
            api: this.$root.api.get.dashboard.chart+'?type=bar',
        }).then((res) => {
          if(res.responseStatus.responseCode == '00') {
            this.labelData.chartData = res.data
            this.labelData.chartData.datasets[0].label = "Grafik Pertumbuhan Anggota"
          }
        })
        .catch((err) => {
            this.$root.isError = true
            if(!navigator.onLine) this.$root.isError = err.message
        })

        this.$root.api.sentAPI(
        {
            method: 'get',
            api: this.$root.api.get.dashboard.savingCalculate,
        }).then((res) => {
            console.log(JSON.stringify(res))
        })
        .catch((err) => {
            this.$root.isError = true
            if(!navigator.onLine) this.$root.isError = err.message
        })

        if(!this.$root.isError) this.$root.overlay = false
    }
  },
  mounted() {
    this.$nextTick(function () {this.$root.overlay = false})
    this.getData()
  }
}
</script>
<style scoped>
</style>
